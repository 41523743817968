import Button from '../../components/Button'
import { PlanType } from '../../types/pricingPlans'

type Props = {
  plan: {
    plan: PlanType
    active: boolean
  }
  onCreate: () => void
}

const EmptyList: React.FC<Props> = ({ plan, onCreate }) => {
  return (
    <div className="empty-list">
      <div className="empty-list__title">You don&apos;t have any aliases yet</div>
      <div className="empty-list__subtitle">
        Aliases are email addresses that forward to your primary email address.
        <br />
        {plan.plan !== PlanType.PRO && (
          <span>
            You can create up to {plan.plan === PlanType.FREE ? 2 : 20} aliases in our <strong>beta</strong> version.
          </span>
        )}
      </div>

      <Button color="light" onClick={onCreate}>
        Create new alias
      </Button>
    </div>
  )
}

export default EmptyList
