import { useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import { RootState } from '../../store'

type Props = {
  id: string
  opened: boolean
  onClose: () => void
  onConfirm: (id: string) => void
}

const ModalCreate: React.FC<Props> = ({ id, opened, onClose, onConfirm }) => {
  const email = useSelector((state: RootState) => state.profile.data.trueEmails.find(email => email.id === id))
  const nbAliases = useSelector(
    (state: RootState) => state.alias.entities.filter(e => e.forwardTo.includes(email?.email || '')).length,
  )

  if (!id || !email) return null

  return (
    <Modal
      header="Are you sure?"
      destructive
      okActionText="Delete"
      cancelActionText="Cancel"
      Content={
        <p className="delete-alias">
          This email <strong>{email.email}</strong> is verified and has <strong>{nbAliases}</strong> forwarding emails
          to it.
          <br />
          <br />
          <strong>Are you sure you want to delete?</strong>
          <br />
          This action is irreversible.
        </p>
      }
      opened={opened}
      onConfirm={() => onConfirm(id)}
      onClose={onClose}
    />
  )
}

export default ModalCreate
