import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (process.env.REACT_APP_DEPLOYED === 'true') {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.REACT_APP_VERSION || 'dev',
    onError: event => {
      event.addMetadata('environment', {
        environment: process.env.REACT_APP_ENVIRONMENT,
        deployed: process.env.REACT_APP_DEPLOYED,
      })
    },
  })
}

type Props = {
  children: React.ReactNode
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const plugin = Bugsnag.getPlugin('react')

  if (plugin) {
    const Component = plugin.createErrorBoundary(React)
    return <Component>{children}</Component>
  } else {
    return <>{children} </>
  }
}

export default ErrorBoundary
