import React from 'react'
import classnames from 'classnames'
import './styles.scss'

type Props = {
  error?: boolean
  label?: string
  placeholder?: string
  type: 'text' | 'password' | 'email'
  value: string
  dark?: boolean
  onBlur?: () => void
  onFocus?: () => void
  onEnter?: () => void
  onChange: (value: string) => void
}

const Input: React.FC<Props> = ({
  error = false,
  label,
  placeholder = '',
  type,
  dark = false,
  value,
  onEnter,
  onChange,
  onBlur,
  onFocus,
}) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter()
    }
  }

  return (
    <div className={classnames('input', { 'input--error': error, dark })}>
      {label && <label className="input__label">{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={event => onChange(event.target.value)}
        onKeyUp={handleKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}

export default Input
