import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'

type NotificationType = {
  id: string
  message: string
  timeout?: number
  type: 'success' | 'error' | 'info' | 'warning'
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    entities: [] as NotificationType[],
  },
  reducers: {
    setNotifications(state, action: PayloadAction<NotificationType[]>) {
      state.entities = action.payload
    },
    addNotification(state, action: PayloadAction<Omit<NotificationType, 'id'>>) {
      state.entities = state.entities.concat({
        id: nanoid(),
        timeout: 5000,
        ...action.payload,
      })
    },
    removeNotification(state, action: PayloadAction<string>) {
      state.entities = state.entities.filter(n => n.id !== action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase('auth/logout', state => {
      state.entities = []
    })
  },
})

export const { setNotifications, addNotification, removeNotification } = notificationsSlice.actions
export default notificationsSlice.reducer
