import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const logged = useSelector<RootState>(state => state.auth.logged)

  if (!logged) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default ProtectedRoute
