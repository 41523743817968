import { useEffect, useState } from 'react'
import { faCheckCircle, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import { notifyError } from '../../logger'
import { verifyEmail } from '../../services/profile'
import { verifyTrueEmail } from '../../store/profile'

const VerifyEmail = () => {
  const navigate = useNavigate()
  const id = new URLSearchParams(window.location.search).get('id') || ''
  const token = new URLSearchParams(window.location.search).get('token') || ''
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (id && token) {
      verifyEmail(id, token)
        .then(() => {
          dispatch(verifyTrueEmail(id))
          setVerified(true)
        })
        .catch(err => {
          switch (err.response?.data?.code) {
            case 'VERIFICATION_TOKEN_EXPIRED':
              setError('The verification link has expired. <br />Go back or your profile to resend a link.')
              break
            case 'VERIFICATION_TOKEN_INVALID':
              setError(
                'The URL provided is invalid. <br />Kindly follow the link in your email to complete the verification process.',
              )
              break

            default:
              notifyError(err)
              setError('Apologies. Something went wrong.<br />We have been notified')
              break
          }
        })
    } else {
      setError(
        'The URL provided is invalid. <br />Kindly follow the link in your email to complete the verification process.',
      )
    }
  }, [id, token, dispatch])

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        navigate('/')
      }, 2000)
    }
  }, [verified, navigate])

  const Component = verified ? (
    <div className="loading__content-success">
      <FontAwesomeIcon color="green" icon={faCheckCircle} size="3x" />
      <br />
      <br />
      <div>Email verified!</div>
    </div>
  ) : error ? (
    <div className="loading__content-error">
      <FontAwesomeIcon icon={faCircleExclamation} color="red" size="2x" />
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: error }} />
    </div>
  ) : (
    <div>
      <FontAwesomeIcon icon={faSpinner} size="2x" spin />
      <p>Verifying your email</p>
    </div>
  )

  return (
    <div className="loading">
      <div className="loading__content">{Component}</div>

      {error && (
        <Button onClick={() => navigate('/profile')} color="danger">
          Go to profile
        </Button>
      )}
    </div>
  )
}

export default VerifyEmail
