import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { notifyError } from '../logger'
import { createTrueEmail, deleteTrueEmail, fetchProfile, Profile } from '../services/profile'
import { PlanType } from '../types/pricingPlans'

export const fetchProfileAsync = createAsyncThunk('profile/fetch', async () => fetchProfile())

const defaultProfile: Profile = {
  uid: '',
  forwardCount: 0,
  trueEmails: [],
  billing: {
    plan: PlanType.FREE,
    active: true,
  },
}

export const createTrueEmailAsync = createAsyncThunk('profile/createTrueEmail', async (email: string) => {
  return createTrueEmail(email).catch(err => {
    notifyError(err)
    return Promise.reject()
  })
})

export const deleteTrueEmailAsync = createAsyncThunk('profile/deleteTrueEmail', async (id: string) => {
  return deleteTrueEmail(id).catch(err => {
    notifyError(err)
    return Promise.reject()
  })
})

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: defaultProfile,
    initialLoad: false,
    loading: false,
    error: null,
  },
  reducers: {
    verifyTrueEmail: (state, { payload }) => {
      const trueEmail = state.data.trueEmails.find(trueEmail => trueEmail.id === payload)
      if (trueEmail) {
        trueEmail.verified = true
      }
    },
  },
  extraReducers: builder => {
    builder.addCase('auth/logout', state => {
      state.data = defaultProfile
      state.initialLoad = false
      state.loading = false
      state.error = null
    })

    builder
      .addCase(fetchProfileAsync.pending, state => {
        state.loading = true
      })
      .addCase(fetchProfileAsync.fulfilled, (state, { payload }) => {
        state.initialLoad = true
        state.loading = false
        state.data = payload
      })
      .addCase(fetchProfileAsync.rejected, state => {
        state.loading = false
      })

    builder.addCase(createTrueEmailAsync.fulfilled, (state, { payload }) => {
      state.loading = false
      state.data.trueEmails = state.data.trueEmails.concat(payload)
    })

    builder.addCase(deleteTrueEmailAsync.fulfilled, (state, { meta }) => {
      state.loading = false
      state.data.trueEmails = state.data.trueEmails.filter(trueEmail => trueEmail.id !== meta.arg)
    })
  },
})

export const { verifyTrueEmail } = profileSlice.actions
export default profileSlice.reducer
