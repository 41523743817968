import React from 'react'
import classnames from 'classnames'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { sendAccountVerification } from '../../services/account'
import { validateEmail } from '../../utils'

const AccountVerification = () => {
  const [email, setEmail] = React.useState<string>('')
  const [inputFocused, setInputFocused] = React.useState<boolean>(false)
  const [sending, setSending] = React.useState<boolean>(false)
  const [sent, setSent] = React.useState<boolean>(false)

  return (
    <div className="account-verification">
      <h1 style={{ fontSize: '2em' }}>
        Welcome to the club
        <span style={{ marginLeft: '5px', color: '#FFF509', fontSize: '2em' }}>.</span>
      </h1>
      <p>Please verify your email address before logging in.</p>

      {!sent ? (
        <div
          className={classnames('account-verification__input-container', {
            focused: inputFocused,
          })}>
          <Input
            type="email"
            value={email}
            onChange={setEmail}
            onBlur={() => setInputFocused(false)}
            onFocus={() => setInputFocused(true)}
          />

          <Button
            onClick={() => {
              setSending(true)
              sendAccountVerification(email).finally(() => {
                setSent(true)
                setSending(false)
              })
            }}
            disabled={!validateEmail(email)}
            loadable
            loading={sending}>
            Send
          </Button>
        </div>
      ) : (
        <div className="account-verification__confirmation">
          <p className="account-verification__confirmation__message">Email successfully sent!</p>

          <p className="account-verification__confirmation__disclaimer">
            If you have not received an email, please check your spam folder, <br />
            or make sure you&apos;ve registered with the correct email address.
          </p>
        </div>
      )}
    </div>
  )
}

export default AccountVerification
