import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { RootState, useAppThunkDispatch } from '../../store'
import { fetchAliasAsync } from '../../store/alias'
import { verifyAuthAsync } from '../../store/auth'
import { fetchMetricsAsync } from '../../store/metrics'
import { fetchProfileAsync } from '../../store/profile'
import './styles.scss'

type Props = {
  children: React.ReactNode
}

const Initializer: React.FC<Props> = ({ children }) => {
  const { initialLoad, logged } = useSelector((state: RootState) => state.auth)
  const userDataLoaded = useSelector((state: RootState) => state.profile.initialLoad && state.alias.initialLoad)
  const dispatchAsync = useAppThunkDispatch()

  useEffect(() => {
    dispatchAsync(verifyAuthAsync())
  }, [dispatchAsync])

  useEffect(() => {
    if (logged) {
      dispatchAsync(fetchProfileAsync())
      dispatchAsync(fetchAliasAsync())
      dispatchAsync(fetchMetricsAsync())
    }
  }, [logged, dispatchAsync])

  if (!initialLoad || (logged && !userDataLoaded)) {
    return (
      <div className="loading">
        <div className="loading__content">
          <Logo height={50} />
          <br />
          Loading app...
        </div>
      </div>
    )
  } else {
    return <>{children}</>
  }
}

export default Initializer
