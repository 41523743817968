import classnames from 'classnames'
import './styles.scss'

type Props = {
  text: string
  status?: 'success' | 'error' | 'warning' | 'info'
}

const Label: React.FC<Props> = ({ status = 'info', text }) => {
  return (
    <div
      className={classnames('label', {
        [`label--${status}`]: !!status,
      })}>
      {text}
    </div>
  )
}

export default Label
