import PricingSection from './components/PricingSection'

const Billing = () => {
  return (
    <div>
      <h1>Billing</h1>
      <PricingSection />
    </div>
  )
}

export default Billing
