import axios from 'axios'
import { getAuth } from 'firebase/auth'

let loggedIn = false
axios.defaults.baseURL =
  process.env.REACT_APP_DEPLOYED !== 'true'
    ? 'http://127.0.0.1:5001/zerocrumb-dev/us-central1/api'
    : process.env.REACT_APP_ENVIRONMENT === 'nonprod'
      ? 'https://us-central1-zerocrumb-dev.cloudfunctions.net/api'
      : 'https://us-central1-zerocrumb-prod-376811.cloudfunctions.net/api'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export const setLoggedStatus = (logged: boolean) => {
  loggedIn = logged
}

axios.interceptors.request.use(
  async config => {
    if (!loggedIn) return config

    try {
      const token = await getAuth().currentUser?.getIdToken(true)
      config.headers['Authorization'] = `Bearer ${token}`
      return config
    } catch (error) {
      console.log(error)
      return config
    }
  },
  error => {
    return Promise.reject(error)
  },
)
