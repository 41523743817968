import classnames from 'classnames'
import './styles.scss'

type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
}

const Toggle: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <div
      className={classnames('toggle toggle--small', {
        'is-active': checked,
      })}
      onClick={() => onChange(!checked)}
    />
  )
}

export default Toggle
