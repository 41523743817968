import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { notifyError } from '../../logger'
import { useAppThunkDispatch } from '../../store'
import { loginAsync } from '../../store/auth'
import { validateEmail } from '../../utils'
import AccountVerification from './AccountVerification'
import './styles.scss'

type Errors = {
  email?: { message: string }
  server?: { message: string }
}

const Register = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errors, setErrors] = useState<Errors>({})
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const [showVerificationScreen, setShowVerificationScreen] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatchAsync = useAppThunkDispatch()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    event.preventDefault()

    setLoggingIn(true)
    dispatchAsync(loginAsync({ email, password }))
      .unwrap()
      .then(({ verified }) => {
        if (verified) {
          navigate('/')
        } else {
          setShowVerificationScreen(true)
        }
      })
      .catch(error => {
        if (error.message === 'SERVER_ERROR') {
          notifyError(error)
          setErrors(errors => ({
            ...errors,
            server: { message: 'Apologies. We cannot log you in, and we have been notified.' },
          }))
        } else if (error.message === 'USER_DISABLED') {
          setErrors(errors => ({ ...errors, server: { message: 'Your account is disabled.' } }))
        } else if (error.message === 'INVALID_CREDENTIALS') {
          setErrors(errors => ({ ...errors, server: { message: 'Either your email or password are incorrect.' } }))
        } else {
          notifyError(error)
          setErrors(errors => ({
            ...errors,
            server: { message: 'Apologies. We cannot log you in, and we have been notified.' },
          }))
        }
      })
      .finally(() => {
        setLoggingIn(false)
      })
  }

  useEffect(() => {
    setErrors(errors => {
      const { email } = errors
      return { email }
    })
  }, [email, password])

  if (showVerificationScreen) {
    return <AccountVerification />
  }

  return (
    <div className="account-form">
      <h1>
        Welcome back<span style={{ marginLeft: '5px', color: '#FFF509', fontSize: '2em' }}>.</span>
      </h1>

      <p>
        No account yet? <Link to="/register">Register here.</Link>
      </p>

      <form onSubmit={onSubmit}>
        <Input
          dark
          error={!!errors.email}
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={email => {
            setEmail(email)
            if (!validateEmail(email) && email.length > 0) {
              setErrors(errors => ({ ...errors, email: { message: 'incorrect email' } }))
            } else {
              setErrors(errors => {
                const { email, ...rest } = errors
                return rest
              })
            }
          }}
        />
        <Input dark type="password" placeholder="Enter your password" value={password} onChange={setPassword} />

        <Button
          disabled={!validateEmail(email) || password.length === 0 || email.length === 0 || loggingIn}
          loading={loggingIn}
          loadable
          type="submit">
          Log into your account
        </Button>
      </form>

      {!!errors.server && (
        <div style={{ position: 'relative' }}>
          <div className="error-container">
            <div className="error-container__error">{errors.server.message}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Register
