import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setLoggedStatus } from '../plugins/axios'
import { accountDelete, login, signOut, verifyAuth } from '../services/auth'

export type AuthType = {
  uid: string
}

export const verifyAuthAsync = createAsyncThunk('auth/verify', async (_, { dispatch }) =>
  verifyAuth().catch((err: unknown) => {
    dispatch(logout())
    return Promise.reject(err)
  }),
)

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (payload: { email: string; password: string }, { dispatch }) => {
    return login(payload.email, payload.password).catch((err: unknown) => {
      dispatch(logout())
      return Promise.reject(err)
    })
  },
)

export const logoutAsync = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  return signOut()
    .then(() => dispatch(logout()))
    .catch((err: unknown) => {
      console.error(err)
      return Promise.reject(err)
    })
})

export const accountDeleteAsync = createAsyncThunk('auth/accountDelete', async (_, { dispatch }) => {
  dispatch(logout())

  return accountDelete().catch((err: unknown) => {
    console.error(err)
    return Promise.reject(err)
  })
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    data: {
      uid: '',
    } as AuthType,
    logged: false,
    logging: false,
    initialLoad: false,
  },
  reducers: {
    logout(state) {
      setLoggedStatus(false)
      state.data = {
        uid: '',
      }
      state.logged = false
      state.initialLoad = true
      state.logging = false
    },

    setAuth(state, action: PayloadAction<AuthType>) {
      state.data = action.payload
      state.logged = true
      state.initialLoad = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginAsync.pending, state => {
        state.logging = true
      })
      .addCase(loginAsync.fulfilled, (state, action: PayloadAction<AuthType & { verified: boolean }>) => {
        const { verified, ...payload } = action.payload
        if (!verified) return

        setLoggedStatus(true)
        state.data = payload
        state.logged = true
        state.logging = false
        state.initialLoad = true
      })
      .addCase(loginAsync.rejected, state => {
        state.logging = false
        state.initialLoad = true
      })

    builder.addCase(verifyAuthAsync.fulfilled, (state, action: PayloadAction<AuthType & { verified: boolean }>) => {
      const { verified, ...payload } = action.payload
      if (!verified) {
        state.initialLoad = true
      }

      setLoggedStatus(true)
      state.data = payload
      state.logged = true
      state.logging = false
      state.initialLoad = true
    })
  },
})

export const { logout, setAuth } = authSlice.actions
export default authSlice.reducer
