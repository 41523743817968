import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import Button from '../../components/Button'
import { AliasType } from '../../services/alias'
import { RootState, useAppThunkDispatch } from '../../store'
import { updateAliasAsync } from '../../store/alias'
import { addNotification } from '../../store/notifications'
import { PlanType, PricingPlans } from '../../types/pricingPlans'
import EmailItem from './EmailItem'
import EmptyList from './EmptyList'
import ModalCreate from './ModalCreate'
import ModalDelete from './ModalDelete'
import './styles.scss'

const Dashboard = () => {
  const [showCreate, setShowCreate] = useState(false)
  const aliases = useSelector((state: RootState) => state.alias.entities)
  const [aliasToDelete, setAliasToDelete] = useState<AliasType>()
  const [showDelete, setShowDelete] = useState(false)
  const billing = useSelector((state: RootState) => state.profile.data.billing)
  const aliasMaxedOut = useSelector(
    (state: RootState) =>
      state.alias.entities.length >= PricingPlans[state.profile.data?.billing?.plan ?? PlanType.FREE].maxAliases,
  )
  const forwardMaxedOut = useSelector(
    (state: RootState) =>
      state.profile.data.forwardCount >= PricingPlans[state.profile.data.billing.plan ?? PlanType.FREE].maxForwards,
  )
  const metrics = useSelector((state: RootState) =>
    state.metrics.entities.reduce(
      (acc, curr) => ({
        blockCount: acc.blockCount + curr.blockCount,
        forwardCount: acc.forwardCount + curr.forwardCount,
      }),
      { blockCount: 0, forwardCount: 0 },
    ),
  )
  const dispatch = useDispatch()
  const dispatchAsync = useAppThunkDispatch()

  return (
    <div className="alias-view" style={{ flex: 1 }}>
      <Tooltip />

      <ModalCreate opened={showCreate} onClose={() => setShowCreate(false)} />

      <ModalDelete alias={aliasToDelete} opened={showDelete} onClose={() => setShowDelete(false)} />

      <h1>Dashboard</h1>

      {aliases.length === 0 ? (
        <EmptyList plan={billing} onCreate={() => setShowCreate(true)} />
      ) : (
        <>
          <div className="stats global">
            <p>
              Emails forwarded: <span className="metric">{metrics.forwardCount}</span>
            </p>
            <p>
              Spam blocked: <span className="metric">{metrics.blockCount}</span>
            </p>
            <p>
              Aliases created: <span className="metric">{aliases.length}</span>
            </p>
          </div>

          <div className="actions">
            <Button color="light" disabled={aliasMaxedOut} onClick={() => setShowCreate(true)}>
              + Create new alias
            </Button>
          </div>

          {aliasMaxedOut && (
            <div className="alias-view__maxed-out">
              You have reached the maximum number of aliases on your current plan.
              <br />
              You can upgrade to a <a href="/billing">higher plan</a> to create more aliases.
            </div>
          )}

          {forwardMaxedOut && (
            <div className="alias-view__maxed-out">
              You have reached the maximum number of active forwards for this month on your current plan. No email will
              be forwarded until you upgrade to a &nbsp;<a href="/billing">higher plan</a> or wait for the next month.
            </div>
          )}

          {aliases.map(alias => (
            <EmailItem
              key={alias.id}
              alias={alias.alias}
              forwardTo={alias.forwardTo}
              paused={alias.paused}
              onDelete={() => {
                setAliasToDelete(() => alias)
                setShowDelete(() => true)
              }}
              onUpdate={async (update: Partial<AliasType>) => {
                if (alias.paused && update.paused === false && forwardMaxedOut) {
                  dispatch(
                    addNotification({
                      message: 'Cannot unpause alias: maximum forwards reached',
                      type: 'error',
                    }),
                  )
                  return
                }
                dispatchAsync(updateAliasAsync({ id: alias.id, ...update }))
                  .unwrap()
                  .then(() => {
                    dispatch(
                      addNotification({
                        message: `Alias ${alias.alias} updated`,
                        type: 'success',
                      }),
                    )
                  })
                  .catch(() => {
                    dispatch(
                      addNotification({
                        message: 'Something went wrong!',
                        type: 'error',
                      }),
                    )
                  })
              }}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default Dashboard
