import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { logEvent } from 'firebase/analytics'
import { Route, Routes, useLocation } from 'react-router-dom'
import ProtectedRoute from '../components/ProtectedRoute'
import AuthLayout from '../layouts/Auth'
import MainLayout from '../layouts/main'
import { analytics } from '../plugins/firebase'
import Billing from './Billing'
import Dashboard from './Dashboard'
import Login from './Login'
import Profile from './Profile'
import Register from './Register'
import VerifyEmail from './VerifyEmail'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      gcTime: 1000 * 60 * 60 * 24 * 7, // 1 week
    },
  },
})

const Router = () => {
  const location = useLocation()

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_path: location.pathname })
  }, [location])

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }>
          <Route index element={<Dashboard />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route
          path="/verify"
          element={
            <ProtectedRoute>
              <VerifyEmail />
            </ProtectedRoute>
          }
        />

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
      </Routes>
    </QueryClientProvider>
  )
}

export default Router
