import { configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { Action, combineReducers } from 'redux'
import alias from './alias'
import auth from './auth'
import metrics from './metrics'
import notifications from './notifications'
import profile from './profile'

const rootReducer = combineReducers({
  alias,
  auth,
  metrics,
  notifications,
  profile,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>()
