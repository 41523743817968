import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MetricsType, subscribeToAliasMetrics } from '../services/metrics'
import { RootState } from '.'

export const fetchMetricsAsync = createAsyncThunk('metrics/fetch', (_, { dispatch, getState }) => {
  const uid = (getState() as RootState).auth.data.uid

  subscribeToAliasMetrics(uid, metrics => {
    dispatch(setMetrics(metrics))
  })
})

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    entities: [] as MetricsType[],
    loading: false,
    error: null as string | null,
  },
  reducers: {
    setMetrics: (state, action) => {
      state.entities = action.payload
    },
  },
})

export const { setMetrics } = metricsSlice.actions
export default metricsSlice.reducer
