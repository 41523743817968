import React from 'react'
import './index.scss'

export const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="loader__spinner" aria-label="Loading indicator">
        <span className="loader__text">
          Loading
          <span className="loader__dots">
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
          </span>
        </span>
      </div>
    </div>
  )
}
