import axios from 'axios'
import { nanoid } from 'nanoid'

export type AliasCreateRequestType = {
  prefix: string
  domain: string
  forwardTo: string[]
}

export type AliasType = {
  id: string
  alias: string
  forwardTo: string[]
  paused: boolean
  blockCount: number
  forwardCount: number
  scheduledAt?: string
  createdAt: string
  updatedAt: string
}

export type AliasUpdateRequestType = {
  id: string
  forwardTo?: string[]
  paused?: boolean
  scheduledAt?: string
}

/**
 * @param prefix
 * @param domain
 */
export const createAlias = (alias: AliasCreateRequestType): Promise<AliasType> =>
  axios.post('/alias', alias).then(res => res.data as AliasType)

/**
 * @param update
 */
export const updateAlias = (update: AliasUpdateRequestType): Promise<void> => {
  const { id, ...payload } = update

  return axios.put(`/alias/${id}`, payload).then(() => Promise.resolve())
}

/**
 * @param id
 */
export const deleteAlias = (id: string): Promise<void> => axios.delete(`/alias/${id}`).then(() => Promise.resolve())

/**
 *
 */

export const fetchAliases = (): Promise<AliasType[]> => axios.get('/alias').then(res => res.data as AliasType[])

/**
 * @param userId
 * @param callback
 * @returns
 * @example
 */
const subscription: (() => void) | undefined = undefined

export const subscribeToAliasUpdates = (_: string, cb: (payload: AliasType[]) => void): void => {
  /** unsubscribe before subscribing */
  subscription !== undefined && (subscription as () => void)()

  aliasStore.on(cb)
  cb(aliasStore.aliases)
}

const Alias = {
  aliases: [] as AliasType[],
  listeners: [] as Array<(payload: AliasType[]) => void>,
  emit() {
    this.listeners.forEach(cb => cb(this.aliases))
  },
  on(cb: (payload: AliasType[]) => void) {
    this.listeners.push(cb)
  },
  create(alias: AliasCreateRequestType) {
    this.aliases = this.aliases.concat({
      id: nanoid(),
      alias: `${alias.prefix}.${nanoid(10)}@${alias.domain}`,
      forwardTo: alias.forwardTo,
      paused: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      forwardCount: 0,
      blockCount: 0,
    })
    this.emit()
  },
  update(alias: AliasUpdateRequestType) {
    this.aliases = this.aliases.map(a =>
      a.id === alias.id ? { ...a, ...alias, updatedAt: new Date().toISOString() } : a,
    )
    this.emit()
  },
  delete(id: string) {
    this.aliases = this.aliases.filter(a => a.id !== id)
    this.emit()
  },
}

const aliasStore = Object.create(Alias)
