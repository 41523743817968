import { useDispatch } from 'react-redux'
import Modal from '../../components/Modal'
import { AliasType } from '../../services/alias'
import { useAppThunkDispatch } from '../../store'
import { deleteAliasAsync } from '../../store/alias'
import { addNotification } from '../../store/notifications'

type Props = {
  alias?: AliasType
  opened: boolean
  onClose: () => void
}

const ModalCreate: React.FC<Props> = ({ alias, opened, onClose }) => {
  const dispatch = useDispatch()
  const dispatchAsync = useAppThunkDispatch()

  if (!alias) return null

  return (
    <Modal
      header="Are you sure?"
      destructive
      okActionText="Delete"
      cancelActionText="Cancel"
      Content={
        <p className="delete-alias">
          Deleting <strong>{alias.alias}</strong> will remove any prevent any new emails from being forwarded to your
          inbox from it.
          <br />
          <br />
          This action is irreversible.
        </p>
      }
      opened={opened}
      onConfirm={() => {
        dispatchAsync(deleteAliasAsync(alias.id))
          .unwrap()
          .then(() => {
            dispatch(
              addNotification({
                message: `Alias ${alias.alias} deleted.`,
                type: 'success',
              }),
            )
          })
          .catch(() => {
            dispatch(
              addNotification({
                message: 'Something went wrong!',
                type: 'error',
              }),
            )
          })

        onClose()
      }}
      onClose={onClose}
    />
  )
}

export default ModalCreate
