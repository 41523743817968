import { FC } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Button from '../../../components/Button'
import './index.scss'

type Props = {
  current: boolean
  price: number
  title: string
  features: string[]
  isPopular?: boolean
  isAnnual: boolean
  stripeLink?: string
}

const PricingCard: FC<Props> = ({ current, price, title, features, isPopular, isAnnual, stripeLink }) => {
  return (
    <Card
      className={`pricing-card ${isPopular ? 'pricing-card--popular' : ''} ${current ? 'pricing-card--current' : ''}`}
      sx={{
        backgroundColor: current ? 'rgba(33, 150, 243, 0.08)' : 'background.paper',
      }}>
      {isPopular && (
        <div className="pricing-card__banner">
          <Typography variant="subtitle2">MOST POPULAR</Typography>
        </div>
      )}
      <CardContent>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>

        <Typography variant="h4" component="h3">
          ${price}
          <Typography variant="subtitle1" component="span">
            {isAnnual ? ' / year' : ' / month'}
          </Typography>
        </Typography>

        <ul>
          {features.map(feature => (
            <li key={feature}>{feature}</li>
          ))}
        </ul>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {current ? (
            <Chip label="Current Plan" color="primary" />
          ) : stripeLink ? (
            <Button onClick={() => window.open(stripeLink, '_blank')}>Subscribe</Button>
          ) : null}
        </Box>
      </CardContent>
    </Card>
  )
}

export default PricingCard
