import { FC, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import Button from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { useQueryProfile, useQueryStripeBillingLinks } from '../../../hooks/useQueryFetches'
import { PlanType, PricingPlans } from '../../../types/pricingPlans'
import PricingCard from './PricingCard'
import './index.scss'

const pricingPlans = Object.values(PricingPlans).map(plan => ({
  ...plan,
  features: [
    plan.id === PlanType.PRO ? 'Unlimited forwards' : `${plan.maxForwards || 'unlimited'} forwards`,
    plan.id === PlanType.PRO ? 'Unlimited email aliases' : `${plan.maxAliases || 'unlimited'} email aliases`,
  ],
  stripeLink: plan.stripeLink,
}))

const mapLinkToPlan = (plan: PlanType.BASIC | PlanType.PRO, isAnnual: boolean) => {
  const planLinks = {
    [PlanType.BASIC]: {
      monthly: 'basicMonthlyPriceCheckoutLink',
      annually: 'basicYearlyPriceCheckoutLink',
    },
    [PlanType.PRO]: {
      monthly: 'proMonthlyPriceCheckoutLink',
      annually: 'proYearlyPriceCheckoutLink',
    },
  }

  return planLinks[plan][isAnnual ? 'annually' : 'monthly']
}

const PricingSection: FC = () => {
  const [isAnnual, setIsAnnual] = useState(false)
  const { data: stripeBillingLinks, isLoading: isStripeBillingLinksLoading } = useQueryStripeBillingLinks()
  const { data: profile, isLoading: isProfileLoading } = useQueryProfile()

  if (isProfileLoading || isStripeBillingLinksLoading) return <Loader />

  if (!profile) throw new Error('Profile not found')

  const handlePlanChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment !== null) {
      setIsAnnual(newAlignment === 'annual')
    }
  }

  return (
    <div className="pricing-section">
      <Typography variant="h2" align="center" gutterBottom>
        Choose your plan
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        No contract or credit card required for the free plan.
      </Typography>

      <ToggleButtonGroup
        value={isAnnual ? 'annual' : 'monthly'}
        exclusive
        onChange={handlePlanChange}
        aria-label="pricing plan"
        className="pricing-toggle">
        <ToggleButton value="monthly" aria-label="monthly">
          Monthly
        </ToggleButton>
        <ToggleButton value="annual" aria-label="annual">
          Yearly <span className="discount">&gt;50% OFF</span>
        </ToggleButton>
      </ToggleButtonGroup>

      <div className="pricing-cards">
        {pricingPlans.map(plan => (
          <PricingCard
            key={plan.id}
            current={plan.id === (profile?.billing?.plan ?? PlanType.FREE)}
            title={plan.title}
            price={isAnnual ? plan.annualPrice : plan.price}
            features={plan.features}
            isPopular={!!plan.isPopular}
            isAnnual={isAnnual}
            stripeLink={
              plan.id === PlanType.FREE
                ? undefined
                : profile.billing.plan !== PlanType.FREE
                  ? stripeBillingLinks?.customerPortalUrl
                  : stripeBillingLinks?.[mapLinkToPlan(plan.id, isAnnual) as keyof typeof stripeBillingLinks]
            }
          />
        ))}
      </div>

      {profile?.billing?.plan !== PlanType.FREE && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
          }}>
          <Button
            color="primary"
            onClick={() => {
              console.log('stripeBillingLinks', stripeBillingLinks)
              window.location.href = stripeBillingLinks?.customerPortalUrl || ''
            }}>
            Manage Subscription
          </Button>
        </div>
      )}
    </div>
  )
}

export default PricingSection
