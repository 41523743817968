export enum PlanType {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
}

type PricingPlan = {
  id: PlanType
  title: string
  maxAliases: number
  maxForwards: number
  price: number
  annualPrice: number
  isPopular?: boolean
  stripeLink?: {
    monthly: string
    annually: string
  }
}

export const PricingPlans: Record<PlanType, PricingPlan> = {
  [PlanType.FREE]: {
    id: PlanType.FREE,
    title: 'Free',
    maxAliases: 2,
    maxForwards: 20,
    price: 0,
    annualPrice: 0,
  },
  [PlanType.BASIC]: {
    id: PlanType.BASIC,
    title: 'Basic',
    maxAliases: 30,
    maxForwards: 1500,
    price: 5.99,
    annualPrice: 29.99,
    isPopular: true,
  },
  [PlanType.PRO]: {
    id: PlanType.PRO,
    title: 'Pro',
    maxAliases: 1000000,
    maxForwards: 10000000000,
    price: 14.99,
    annualPrice: 74.99,
  },
}
