import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { RootState } from '../../store'
import './styles.scss'

const AuthLayout = () => {
  const logged = useSelector((state: RootState) => state.auth.logged)

  if (logged) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <div id="auth" className="row">
      <div id="content">
        <Outlet />
      </div>
    </div>
  )
}

export default AuthLayout
