import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Initializer from './components/Initializer'
import Notifications from './components/Notifications'
import ErrorBoundary from './plugins/bugsnag'
import reportWebVitals from './reportWebVitals'
import Router from './routes'
import { store } from './store'
import './index.scss'
import 'react-tooltip/dist/react-tooltip.css'
import './plugins/axios'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <Notifications />
        <Initializer>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Initializer>
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
