import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

type Props = {
  selected?: boolean
  text: string
  to: string
}

const Item: React.FC<Props> = ({ selected = false, text, to }) => {
  return (
    <Link className={classnames('item', { selected })} to={to}>
      {text}
    </Link>
  )
}

export default Item
