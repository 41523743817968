import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import Button from '../Button'
import './index.scss'

type Props = {
  header?: string
  body?: string
  destructive?: boolean
  disableAction?: boolean
  footer?: string
  okActionText?: string
  cancelActionText?: string
  Content?: React.ReactNode
  opened: boolean
  onClose: () => void
  onConfirm?: () => void
}

const Modal: React.FC<Props> = ({
  header,
  body,
  destructive,
  disableAction,
  okActionText = 'Confirm',
  cancelActionText = 'Cancel',
  Content,
  opened = false,
  onClose,
  onConfirm,
}) => {
  return (
    <div
      className={classnames('modal', {
        'modal--opened': opened,
      })}
      onClick={onClose}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <div className="modal__header">
          <h1>{header || 'Modal'}</h1>
          <Button clear onClick={onClose}>
            <FontAwesomeIcon size="xl" icon={faTimes} />
          </Button>
        </div>

        <div className="modal__body">{Content ? Content : <p>{body}</p>}</div>

        <div className="modal__footer">
          <Button color={destructive ? 'danger' : 'primary'} disabled={!!disableAction} onClick={onConfirm}>
            {okActionText}
          </Button>

          <Button color="light" onClick={onClose}>
            {cancelActionText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Modal
