import { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import { RootState } from '../../store'
import { removeNotification } from '../../store/notifications'
import Button from '../Button'
import './styles.scss'

const ANIMATION_DURATION = 300

const Notifications = () => {
  const notification = useSelector((state: RootState) => state.notifications.entities[0])
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()

  const rStyle = useSpring({
    transform: show ? 'translateY(0px)' : 'translateY(-100px)',
    opacity: show ? 1 : 0,
    config: {
      duration: ANIMATION_DURATION,
    },
  })

  const onClose = useCallback(() => {
    setShow(false)
    setTimeout(() => {
      dispatch(removeNotification(notification.id))
    }, ANIMATION_DURATION + 100)
  }, [notification, dispatch])

  useEffect(() => {
    if (!!notification) {
      setShow(true)

      setTimeout(onClose, notification.timeout)
    }
  }, [notification, onClose])

  return (
    <div className={classnames('notifications')}>
      <animated.div
        className={classnames(
          'notifications__content',
          notification?.type && `notifications__content--${notification?.type}`,
        )}
        style={rStyle}>
        <p>{notification?.message}</p>

        <div className="notifications__content__close">
          <Button
            clear
            onClick={() => {
              setShow(false)
            }}>
            Close
          </Button>
        </div>
      </animated.div>
    </div>
  )
}

export default Notifications
