import { useQuery } from '@tanstack/react-query'
import { fetchProfile, getStripeBillingLinks } from '../services/profile'

export const useQueryProfile = () => {
  const query = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile,
  })

  return query
}

export const useQueryStripeBillingLinks = () => {
  const query = useQuery({
    queryKey: ['stripe-billing-links'],
    queryFn: getStripeBillingLinks,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 0,
  })

  return query
}
