import { useState } from 'react'
import { faCopy as farCopy } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { TooltipWrapper } from 'react-tooltip'
import Button from '../../components/Button'
import Toggle from '../../components/Toggle'
import { AliasType } from '../../services/alias'
import { RootState } from '../../store'

type Props = {
  alias: string
  forwardTo: string[]
  paused: boolean
  onDelete: () => void
  onUpdate: (update: Partial<AliasType>) => void
}

type Variant = 'dark' | 'success'

const EmailItem: React.FC<Props> = ({ alias, forwardTo, paused, onDelete, onUpdate }) => {
  const metrics = useSelector((state: RootState) => state.metrics.entities.find(m => m.alias === alias))

  const [tooltipConfig, setTooltipConfig] = useState({
    content: 'Click to copy',
    delayHide: 0,
    variant: 'dark' as Variant,
  })

  const onCopyEmail = () => {
    navigator.clipboard.writeText(alias)
    setTooltipConfig({
      content: 'Copied!',
      delayHide: 1000,
      variant: 'success',
    })
  }

  return (
    <div className="email-item">
      <TooltipWrapper content={paused ? 'Not receiving emails' : 'Receiving'}>
        <Toggle
          checked={!paused}
          onChange={async checked => {
            await onUpdate({ paused: !checked })
          }}
        />
      </TooltipWrapper>

      <div className="email-item__content">
        <div
          className="email-item__alias email-item__alias--active"
          onClick={onCopyEmail}
          onMouseEnter={() => {
            setTimeout(() => {
              setTooltipConfig({
                content: 'Click to copy',
                delayHide: 0,
                variant: 'dark',
              })
            }, 100)
          }}>
          <TooltipWrapper {...tooltipConfig}>
            {alias}
            <FontAwesomeIcon icon={farCopy} />
          </TooltipWrapper>
        </div>

        <div className="stats">
          <p>
            Forwarded: <span>{metrics?.forwardCount || 0}</span>
          </p>
          <p>
            Blocked: <span>{metrics?.blockCount || 0}</span>
          </p>
          <p>
            Recipients: <span>{forwardTo.length}</span>
          </p>
        </div>
      </div>

      <div className="email-item__actions">
        <Button color="danger" onClick={onDelete}>
          <FontAwesomeIcon color="white" icon={faTrashAlt} />
        </Button>
      </div>
    </div>
  )
}

export default EmailItem
